<template>
  <div class="Onebyone">
    <div class="minw homeDivCss">
      <div class="bottom" v-loading="loading">
        <div style="display:flex;align-items: center; justify-content:space-between">
          <el-row :gutter="20">
            <el-col :span="3">
              <el-select v-model="queryParams.particularYear" placeholder="请选择年份">
                <el-option v-for="index of 5" :key="index" :label="new Date().getFullYear() - index + 1"
                  :value="new Date().getFullYear() - index + 1">
                </el-option>
              </el-select>
            </el-col>

            <el-col :span="3">
              <el-select v-model="queryParams.subjects" placeholder="请选择科目">
                <el-option label="物理" value="物理"></el-option>
                <el-option label="历史" value="历史"></el-option>
              </el-select>
            </el-col>
            <el-col :span="14" style="margin-left: 0px;width: 455px;">
              <div class="searchInput">
                <el-input v-model="queryParams.score" placeholder="请输入分数" oninput="value=value.replace(/[^0-9.]/g,'')"
                  @keyup.enter.native="getList" clearable></el-input>
                <div class="divicon" @click="handlerClick()">
                  <i class="el-icon-search" style="color: white;"></i>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 个人信息 -->
        <div class="middlebox">
          <!-- <div>
            <p>{{ userInfo.expirationTime ? userInfo.expirationTime.split('-')[0] : '---' }}</p>
            <span>高考年份</span>
          </div> -->
          <div>
            <p>{{ userScore || '---' }}分</p>
            <span>高考分数</span>
          </div>
          <div>
            <p>{{ userSubject || '---' }}</p>
            <span>科目</span>
          </div>
          <div>
            <p>{{ ranking || '---' }}</p>
            <span>位次</span>
          </div>
        </div>
        <div class="lastbox">
          <div class="onebyone_left">
            <h1>历年等位分</h1>
            <div class="commonBox">
              <div class="yearBox">
                <div>年份</div>
                <div>位次</div>
                <div>等位分</div>
              </div>
              <div v-for="(item, index) in  lastAllYear" :key="index" class="yearBox">
                <div>{{ item.particularYear || '--' }}</div>
                <div>{{ item.cumulativePopulation || '--' }}</div>
                <div>{{ item.showScore || '--' }}</div>
              </div>
            </div>
          </div>
          <div class="onebyone_right">
            <!-- <h1>本省{{ queryParams.particularYear }} {{ queryParams.subjects }}分段表</h1> -->
            <h1>分段表</h1>
            <div class="commonBox">
              <div>
                <div class="yearBox">
                  <div>年份</div>
                  <div>分数</div>
                  <div style="flex:2">位次区间</div>
                  <div>本段人数</div>
                </div>
                <div v-for="(item, index) in  tableDataXiaoXi " :key="index" class="yearBox">
                  <div>{{ item.particularYear || '--' }}</div>
                  <div>{{ item.showScore || '--' }}</div>
                  <div style="flex:2"> {{ item.cumulativePopulationAll || '--' }}</div>
                  <div>{{ item.population || '--' }}</div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <pagination v-show="queryParams.total > 1 && loading == false" :total="queryParams.total"
          :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList()" />
        <div class="tip">
          <div> 1.位次查询结果根据省招生办发布一分一段表计算，仅供参考；</div>
          <div> 2.正式填报时，以招生办公布的高考成绩（位次）为准。</div>
          <div>3.“--”表示该分数对应的往年位次在官方数据中不存在，无法查询到结果；</div>
          <div> 4.解释权归本平台所有。</div>
        </div>
        <!-- list渲染 -->
        <!-- <el-table :data="tableDataXiaoXi" style="margin-top:30px;border-radius:10px;border:1px solid #edebeb"
          :header-cell-style="{
            backgroundColor: '#f4f4f4',
            color: 'black',
            fontSize: '17px',
            textAlign: 'center',
          }" :cell-style="{
  textAlign: 'center',
  border: '1px solid #edebeb'
}">
          <el-table-column prop="levelProvince" label="省份">
          </el-table-column>
          <el-table-column prop="particularYear" label="年份">
          </el-table-column>
          <el-table-column prop="subjects" label="科目">
          </el-table-column>
          <el-table-column prop="showScore" label="分数">
          </el-table-column>
          <el-table-column prop="cumulativePopulation" label="累计人数">
          </el-table-column>
          <el-table-column prop="population" label="本段人数">
          </el-table-column>
        </el-table> -->

      </div>

    </div>
  </div>
</template>

<script>
import { REQlevelscore } from "@/api/homeIndex.js";
import { mapState } from 'vuex'
import {
  REQRank,
} from "@/api/aspiration.js";
export default {
  name: "Onebyone",
  data() {
    return {
      tableDataXiaoXi: [],
      lastAllYear: [],
      ranking: 0,
      userScore: '',
      userSubject: '',
      loading: false,
      queryParams: {
        total: 1,
        pageNum: 1,
        pageSize: 3,
        particularYear: "",
        levelProvince: "河北省",
        subjects: "物理",
        score: ""
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  created() {
    // 系统时间小于6.25展示去年，反之展示今年。
    let serverTime = new Date(this.$store.state.serverTime)
    if (serverTime.getMonth() < 5) {
      this.queryParams.particularYear = serverTime.getFullYear() - 1;
    } else if (serverTime.getMonth() == 5) {
      if (serverTime.getDate() <= 25) {
        this.queryParams.particularYear = serverTime.getFullYear() - 1;
      } else {
        this.queryParams.particularYear = serverTime.getFullYear()
      }
    }
    else {
      this.queryParams.particularYear = serverTime.getFullYear()
    }
    this.getList();
    // this.getRank(this.userInfo.score,this.userInfo.subject)
  },
  methods: {
    async getRank(score, subjects) {
      if (!score || !subjects) {
        return
      }
      const self = this
      let particularYear = ''
      const serverTime = new Date(this.$store.state.serverTime)
      const nowDate = serverTime.getTime()        //获取当前时间
      const startTime = await this.getAlltime('1')    //1是查询位次时间
      if (nowDate < Date.parse(startTime)) {
        particularYear = serverTime.getFullYear() - 1
      } else {
        particularYear = serverTime.getFullYear()
      }
      const obj = {
        levelProvince: '河北省',
        particularYear: particularYear,
        subjects,
        score
      }
      await REQRank(obj).then((res) => {
        if (res.code == 200) {
          self.ranking = res.data.cumulativePopulation || ''
        }
      })
    },
    handlerClick() {
      this.queryParams.pageSize = 3;
      this.queryParams.pageNum = 1;
      this.getList()
    },
    getList() {
      const phoneNumber = localStorage.getItem('phonenumber')
      if (!phoneNumber) {
        const channel = new BroadcastChannel('share')
        channel.postMessage(true)
        return
      }
      const self = this;
      self.loading = true;
      this.queryParams.phoneNumber = phoneNumber
      REQlevelscore(this.queryParams).then((res) => {
        if (res.code == 200) {
          self.loading = false;
          self.tableDataXiaoXi = res.data.leveScoreInfo.beforeYList;
          // self.queryParams.total = res.data.dataTable.total;
          self.lastAllYear = res.data.leveScoreInfo.beforeRankList.map(item=>{
                return {
                  ...item,
                  showScore:(!item.showScore || item.showScore == '0') ? '--' : item.showScore
                }
          })
          self.userScore = res.data.leveScoreInfo.score
          self.ranking = res.data.leveScoreInfo.cumulativePopulation
          self.userSubject = res.data.leveScoreInfo.subject
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Onebyone {
  user-select: none;
  // background: rgb(252, 251, 249);
  padding: 10px 0 0px 0;

  .homeDivCss .bottom {}

  .homeDivCss .bottom::-webkit-scrollbar {
    display: none;
  }

  .bottom {
    min-height: 0px;

    .line {
      margin: 3px;
      height: 3px !important;
      width: 35px;
      background: #30C9B2;
      margin-bottom: 10px;
    }

    .title {
      font-weight: 900;
      font-size: 18px;
    }

    .XiaoXibox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      padding: 25px 20px;
      margin-bottom: 20px;
      cursor: pointer;
      font-size: 20px;

      div:nth-of-type(1):hover {
        color: #30C9B2;
      }

      p {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        background: #30C9B2;
        margin-right: 10px;
      }

      h2 {
        display: inline-block;
      }
    }
  }

  .middlebox {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;

    div {
      flex: 1;
      text-align: center;
    }

    p {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #000000;
    }

    span {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #B3B3B3;
      line-height: 40px;
    }
  }

  .lastbox {
    display: flex;
    margin: 60px 0 10px 0;

    h1 {
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #12B098;
      line-height: 37px;
    }

    .onebyone_left {
      margin-right: 13px;
    }

    .onebyone_left,
    .onebyone_right {
      box-sizing: border-box;
      padding-top: 15px;
      flex: 1;
      background: #fbfbfb;
      border: 1px solid #F5F5F5;
      box-shadow: 0px 3px 12px 0px rgba(156, 157, 156, 0.03);
      border-radius: 10px;
    }

    .commonBox {
      background: #fbfbfb;
      box-shadow: 0rpx 16px 46px 0px rgba(28, 44, 29, 0.17);
      border-radius: 20px;
      padding: 20px;
    }

    .yearBox {
      display: flex;
      align-items: center;
      color: #12B098;
      flex: 1;
      font-size: 16px;
    }

    .yearBox div {
      text-align: center;
      flex: 1;
      margin: 10px;
    }
  }

  .tip {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #B3B3B3;
    margin-top: 30px;

    div {
      line-height: 30px;
    }
  }
}
</style>
